import React from "react"
import BackButton from "./backButton"
import * as twoColumnStyles from "./twoColumnComponent.module.scss"

function TwoColumnComponent(props) {
    const { title, subtitle, children, backbutton, linkDestination, titleContent } = props
    return(
        <div className="columns">
            <div className={`column is-6 pr-5 ${twoColumnStyles.titles}`}>
                <div className={`${backbutton ? twoColumnStyles.backButtonContainer: ""}`}>
                {backbutton && <div className="mr-6">
                    <BackButton to={linkDestination}/>
                </div>}
                {title && <h1 className="title is-1">{title}</h1>}
                </div>
                {subtitle && <h2 className="subtitle">{subtitle}</h2>}
                {titleContent &&<p className={twoColumnStyles.titleContent}>{titleContent}</p>}
            </div>
            <div className="column is-6">
                {children}
            </div>
        </div>  
    )
}

export default TwoColumnComponent
