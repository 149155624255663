import React from "react"
import { navigate } from "gatsby";
import * as buttonStyles from "./backButton.module.scss"
import {FiChevronLeft} from "@react-icons/all-files/fi/FiChevronLeft"

function BackButton() {
    return(
        <div className={buttonStyles.button} onClick={() => navigate(-1)}>
            <div className={buttonStyles.buttonSymbol}>
                <FiChevronLeft />
            </div>
        </div>
    )
}

export default BackButton